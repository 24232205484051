export default [
  [{ folha: 'Início', papeis: ["ROLE_ADMIN", "ROLE_CONTRATANTE", "ROLE_CONTRATADA", "ROLE_CADASTRO", "ROLE_AREASECUNDARIA", "ROLE_VIGILANTE", "ROLE_MOTORISTA"] }],
  [
    { to: '/', icon: 'desktop-mac', label: 'Dashboard' },
  ],

  [{ folha: 'Veículos', papeis: ["ROLE_ADMIN", "ROLE_CONTRATANTE", "ROLE_CONTRATADA", "ROLE_CADASTRO", "ROLE_AREASECUNDARIA", "ROLE_VIGILANTE"] }],
  [
    { to: '/veiculos?page=1&area=PRIMARIA&classificacao=AUTOMOVEL&orderBy=id,DESC', label: 'Área Primária', icon: 'truck-outline', papeis: ["ROLE_ADMIN", "ROLE_CONTRATANTE", "ROLE_CONTRATADA", "ROLE_CADASTRO"] },

    { to: '/veiculos?page=1&area=SECUNDARIA&classificacao=AUTOMOVEL&orderBy=id,DESC', label: 'Área Secundária', icon: 'truck-outline', papeis: ["ROLE_ADMIN", "ROLE_CONTRATANTE", "ROLE_CONTRATADA", "ROLE_CADASTRO", "ROLE_AREASECUNDARIA"] },

    { to: '/veiculos?page=1&classificacao=MOTOCICLETA&orderBy=id,DESC', label: 'Motociletas', icon: 'motorbike', papeis: ["ROLE_ADMIN", "ROLE_CONTRATANTE", "ROLE_CONTRATADA", "ROLE_CADASTRO"] },

    { to: '/veiculos?page=1&tipo=AVULSO&orderBy=id,DESC', label: 'Avulso', icon: 'truck-outline', papeis: ["ROLE_ADMIN", "ROLE_CONTRATANTE", "ROLE_CONTRATADA", "ROLE_CADASTRO"] },

    { to: '/ativacoes?page=1&orderBy=id,DESC', label: 'Ativações', icon: 'lock-open-variant-outline', papeis: ["ROLE_ADMIN", "ROLE_VIGILANTE"] },

    { to: '/ConsultarPlaca', label: 'Consultar Placa', icon: 'table-search', papeis: ["ROLE_ADMIN", "ROLE_VIGILANTE"] },
  ],

  [{ folha: 'Pessoas', papeis: ["ROLE_ADMIN", "ROLE_CONTRATANTE", "ROLE_CONTRATADA", "ROLE_CADASTRO", "ROLE_MOTORISTA"] }],
  [
    { to: '/pessoas?page=1&tipoPessoa=FUNCIONARIO&orderBy=nome,ASC', label: 'Funcionários', icon: 'account-multiple', papeis: ["ROLE_ADMIN", "ROLE_CONTRATANTE", "ROLE_CONTRATADA", "ROLE_CADASTRO"] },
    { to: '/pessoas?page=1&tipoPessoa=MOTORISTA&orderBy=nome,ASC', label: 'Motoristas', icon: 'account-badge-outline', papeis: ["ROLE_ADMIN", "ROLE_MOTORISTA"] },
    { to: '/pessoas?page=1&tipoPessoa=VISITANTE&orderBy=nome,ASC', label: 'Visitantes', icon: 'account-badge-outline', papeis: ["ROLE_ADMIN", "ROLE_CONTRATANTE", "ROLE_CONTRATADA", "ROLE_CADASTRO"] },
  ],

  [{ folha: 'Empresas', papeis: ["ROLE_ADMIN", "ROLE_CONTRATANTE"] }],
  [
    { to: '/externos?page=1&tipo=CONTRATANTE&orderBy=id,DESC', label: 'Contratantes', icon: 'city-variant-outline', papeis: ["ROLE_ADMIN"] },
    { to: '/externos?page=1&tipo=CONTRATADA&orderBy=id,DESC', label: 'Contratadas', icon: 'city-variant-outline', papeis: ["ROLE_ADMIN", "ROLE_CONTRATANTE"] },
    { to: '/externos?page=1&tipo=PATIO_TRIAGEM&orderBy=id,DESC', label: 'Pátio de Triagem', icon: 'city-variant-outline', papeis: ["ROLE_ADMIN"] },
    { to: '/externos?page=1&tipo=TERMINAL&orderBy=id,DESC', label: 'Terminais', icon: 'city-variant-outline', papeis: ["ROLE_ADMIN"] }
  ],
 
  [{ folha: 'Autorizações', papeis: ["ROLE_ADMIN", "ROLE_PATIO_TRIAGEM", "ROLE_TERMINAL"] }
  ],
  [
    { to: '/agendamentos?page=1&tipo=GRAO&orderBy=id,DESC', label: 'Grão', icon: 'barn', papeis: ["ROLE_ADMIN",  "ROLE_PATIO_TRIAGEM", "ROLE_TERMINAL"] },
    { to: '/agendamentos?page=1&tipo=TANQUE&orderBy=id,DESC', label: 'Tanque', icon: 'barn', papeis: ["ROLE_ADMIN",  "ROLE_PATIO_TRIAGEM", "ROLE_TERMINAL"] },
  ],

  [{ folha: 'Configurações', papeis: ["ROLE_ADMIN"] }],
  [
    { to: '/usuarios?page=1&orderBy=id,DESC', label: 'Usuários', icon: 'account-group-outline', papeis: ["ROLE_ADMIN"] },
    { to: '/gerenciarPerfis?page=1&orderBy=id,DESC', label: 'Gerenciar Perfis', icon: 'account-cog-outline', papeis: ["ROLE_ADMIN"] },
  ],
]

