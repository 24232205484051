<template>
    <div>
        <title-bar :title-stack="titleStack" />
        <hero-bar>
            Formulário
            <router-link slot="right" to="/" class="button">
                Dashboard
            </router-link>
        </hero-bar>
        <section class="section is-main-section">
            <b-message title="Cadastro de Motorista" type="is-info" has-icon aria-close-label="Close message" auto-close
                :duration="10000" v-if="tipoPessoa == 'Motorista'">
                Atenção. Para cadastrar "MOTORISTA" é obrigatório informar: <br>
                *CNH, <br>
                *Data Validade CNH, <br>
                *FOTO <br>
                *MOPP / CNH DIGITAL <br>
            </b-message>
            <form @submit.prevent="formAction" class="box">
                <card-component :title="getTitle()" icon="ballot">
                    <b-field label="Foto" horizontal v-if="tipoPessoa == 'Motorista'">
                        <b-field class="file is-info" :class="{ 'has-name': !!form.pessoa.foto }">
                            <div style="width: 100%; display: flex; flex-direction: column;">
                                <div style="width: 200px;">
                                    <b-image v-if="image" :src="image" alt="Imagem"
                                        style="border-width: 3px; border-style: solid; border-color:  #0f77ea;"></b-image>
                                    <b-image v-else :src="require('@/assets/default.png')" alt="Imagem"
                                        style="border-width: 3px; border-style: solid; border-color: #cecece;"></b-image>
                                </div>
                                <div style="width: 200px;">
                                    <b-upload class="file-label" v-model="form.pessoa.arquivo" name="image"
                                        label="Selecionar imagem" @input="uploadImage" style="padding-top:0px;">
                                        <span class="file-cta"
                                            style="width: 200px; z-index: 999; background-color: #0f77ea; border-radius: 0;">
                                            <b-icon class="file-icon" icon="upload"></b-icon>
                                            <span class="file-label">Selecionar Foto</span>
                                        </span>
                                    </b-upload>
                                </div>
                            </div>
                        </b-field>
                    </b-field>
                    <hr v-if="form.pessoa.tipoPessoa == 'MOTORISTA'">
                    <b-field label="Dados Pessoais" horizontal>
                        <b-field style="width: 300px;" label="Selecione uma Empresa*" :label-position="labelPosition">
                            <b-select v-model="form.pessoa.idEmpresa" placeholder="Selecione uma Empresa*" required data-js="empresa">
                                <option v-for="empresa in getEmpresas" :key="empresa.id" :value="empresa.id">
                                    {{ empresa.razaoSocial }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field label="Nome*" :label-position="labelPosition">
                            <b-input v-model="form.pessoa.nome" icon="account-circle-outline" placeholder="Nome*"
                                name="tipoVeiculo" required />
                        </b-field>

                        <b-field label="Tipo Pessoa*" :label-position="labelPosition">
                            <b-input v-model="form.pessoa.tipoPessoa" icon="account-circle-outline"
                                placeholder="Tipo pessoa*" name="tipoPessoa" required disabled />
                        </b-field>
                    </b-field>
                    <b-field horizontal>
                        <b-field label="Estrangeiro ?" :label-position="labelPosition">
                            <b-select v-model="form.pessoa.estrangeiro" placeholder="Estrangeiro" expanded
                                icon="card-bulleted-outline">
                                <option :value=true>Sim</option>
                                <option :value=false>Não</option>
                            </b-select>
                        </b-field>

                        <b-field label="CPF*" :label-position="labelPosition" v-if="!form.pessoa.estrangeiro">
                            <b-input v-model="form.pessoa.cpf" icon="card-bulleted-outline" placeholder="CPF*" name="CPF"
                                v-mask="'###.###.###-##'" required />
                        </b-field>
                        <b-field label="RG" :label-position="labelPosition" v-if="!form.pessoa.estrangeiro">
                            <b-input v-model="form.pessoa.rg" icon="card-bulleted-outline" placeholder="RG" name="RG"
                                type="number" />
                        </b-field>
                        <b-field label="Orgão Expedidor" :label-position="labelPosition">
                            <b-input v-model="form.pessoa.orgaoExpedidor" icon="card-bulleted-outline"
                                placeholder="Orgão Expedidor" name="orgaoExp" />
                        </b-field>
                    </b-field>

                    <b-field horizontal>
                        <b-field label="Passaporte" :label-position="labelPosition">
                            <b-input v-model="form.pessoa.passaporte" icon="card-bulleted-outline" placeholder="Passaporte"
                                data-js="passaporte" name="passaporte" />
                        </b-field>
                        <b-field label="CNH*" :label-position="labelPosition">
                            <b-input v-model="form.pessoa.cnh" icon="card-bulleted-outline" placeholder="CNH*" name="CNH"
                                data-js="cnh" :required="false" />
                        </b-field>
                        <b-field label="Data Validade CNH" :label-position="labelPosition">
                            <input type="date"  v-model="form.pessoa.validadeCnh" class="input" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}">
                        </b-field>
                        <b-field label="Código do Cartão" :label-position="labelPosition">
                            <b-input v-model="form.pessoa.numeroCartao" icon="card-bulleted-outline"
                                placeholder="Código do Cartão" name="codCartao" />
                        </b-field>
                        <!-- <b-field class="file is-primary" :class="{'has-name': !!file}"  v-if="form.pessoa.tipoPessoa == 'MOTORISTA'">
                            <b-upload v-model="form.pessoa.moppCnhFile" class="file-label">
                                <span class="file-cta">
                                    <b-icon class="file-icon" icon="upload"></b-icon>
                                    <span class="file-label">Anexar MOPP / CNH digital*</span>
                                </span>
                                 <span class="file-name" v-if="file">
                                    {{ file.name }}
                                </span> 
                            </b-upload>
                        </b-field> -->
                    </b-field>

                    <b-field horizontal>
                        <b-field label="Crachá" :label-position="labelPosition">
                            <b-input v-model="form.pessoa.cracha" icon="card-bulleted-outline" placeholder="Crachá"
                                name="cracha" />
                        </b-field>
                        <b-field label="Telefone" :label-position="labelPosition">
                            <b-input v-model="form.pessoa.telefone" icon="phone" placeholder="Telefone" name="telefone"
                                v-mask="'(##) ####-####'" />
                        </b-field>
                        <b-field label="Celular" :label-position="labelPosition">
                            <b-input v-model="form.pessoa.celular" icon="cellphone-iphone" placeholder="Celular"
                                name="celular" v-mask="'(##) #####-####'" />
                        </b-field>
                        <b-field label="Email" :label-position="labelPosition">
                            <b-input v-model="form.pessoa.email" icon="email-outline" placeholder="E-mail" name="email"
                                type="email" />
                        </b-field>
                    </b-field>

                    <b-field horizontal>
                        <b-field label="Cargo" :label-position="labelPosition">
                            <b-input v-model="form.pessoa.cargo" icon="account-tie-outline" placeholder="Cargo"
                                name="cargo" />
                        </b-field>
                        <b-field label="Data Desligamento" :label-position="labelPosition">
                            <input type="date"  v-model="form.pessoa.dataDesligamento" class="input" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}">
                        </b-field>
                        <b-field label="Data Início Acesso" :label-position="labelPosition">
                            <input type="date"  v-model="form.pessoa.dataInicioAcesso" class="input" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}">
                        </b-field>
                        <b-field label="Data Fim Acesso" :label-position="labelPosition">
                            <input type="date"  v-model="form.pessoa.dataFimAcesso" class="input" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}">
                        </b-field>
                    </b-field>
                    <hr>
                    <b-field label="Endereço" horizontal>
                        <b-field label="Estado" :label-position="labelPosition">
                            <b-select v-model="form.pessoa.uf" placeholder="Selecione o estado" expanded>
                                <option v-for="(estado, index) in estados" :key="index" :value="estado">
                                    {{ estado }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-field label="Município" :label-position="labelPosition">
                            <b-select v-model="form.pessoa.idMunicipio" placeholder="Selecione um municipio"
                                expanded>
                                <option v-for="municipio in getMunicipios" :key="municipio.id" :value="municipio.id">
                                    {{ municipio.nome }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-field label="Longradouro" :label-position="labelPosition">
                            <b-input v-model="form.pessoa.logradouro" icon="map-marker-outline" placeholder="Logradouro"
                                name="logradouro" />
                        </b-field>
                    </b-field>
                    <b-field horizontal>
                        <b-field label="Número" :label-position="labelPosition">
                            <b-input v-model="form.pessoa.numero" icon="map-marker-outline" placeholder="Número"
                                name="numero" />
                        </b-field>
                        <b-field label="Bairro" :label-position="labelPosition">
                            <b-input v-model="form.pessoa.bairro" icon="map-marker-outline" placeholder="Bairro"
                                name="bairro" />
                        </b-field>
                        <b-field label="Complemento" :label-position="labelPosition">
                            <b-input v-model="form.pessoa.complemento" icon="map-marker-outline" placeholder="Complemento"
                                name="complemento" />
                        </b-field>
                        <b-field label="CEP" :label-position="labelPosition">
                            <b-input v-model="form.pessoa.cep" icon="map-marker-outline" placeholder="CEP" name="CEP"
                                v-mask="'#####-###'" />
                        </b-field>

                        <b-field label="Status*" :label-position="labelPosition" v-if="$util.hasAnyRole(['ROLE_ADMIN', 'ROLE_CADASTRO'])">
                            <b-select v-model="form.pessoa.status" placeholder="Selecione um status*" required
                                expanded>
                                <option v-for="s in status" :key="s.id" :value="s.value">
                                    {{ s.label }}
                                </option>
                            </b-select>
                        </b-field>

                    </b-field>
                    <b-field v-if="this.$route.query.tipoPessoa == 'VISITANTE'" horizontal>
                        <b-field label="Justificativa da Visita" :label-position="labelPosition">
                            <b-input v-model="form.pessoa.justificativa" type="textarea" minlength="3"
                                placeholder="Justificativa da visita" maxlength="255" data-js="justificativa" />
                        </b-field>
                    </b-field>

                    <b-field horizontal>
                        <b-field>
                            <div class="control">
                                <b-button native-type="submit" type="is-info" :loading="isLoading">
                                    Salvar
                                </b-button>
                            </div>
                        </b-field>
                    </b-field>
                </card-component>
            </form>

            <!-- Form de documentos após create -->

            <form @submit.prevent="formDocsAction" v-if="$route.params.id" class="box">
                <card-component title="Documentação" icon="file">

                    <b-field horizontal label="Documentos">

                        <b-field label="Tipo Documento">
                            <b-select v-model="formDocs.tipo" placeholder="Selecione o estado*" required expanded>
                                <option v-for="(tipo, index) in tiposDoc" :key="index" :value="tipo.valor">
                                    {{ tipo.label }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field label="Data Documento">
                            <input type="date"  v-model="formDocs.data" class="input" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}">
                        </b-field>

                        <b-field label="Documento">
                            <file-picker v-model="formDocs.file" type="is-info" :required="true" />
                        </b-field>
                    </b-field>

                    <br>

                    <b-field horizontal>
                        <b-field>
                            <div class="control">
                                <b-button native-type="submit" type="is-info" :loading="isLoading">
                                    Enviar
                                </b-button>
                            </div>
                        </b-field>
                    </b-field>

                    <br>

                    <b-field horizontal>
                        <b-table :data="documentos" striped hoverable style="border: 1px solid rgb(225, 225, 225);">

                            <b-table-column v-slot="doc" label="Tipo Documento" field="tipo">
                                {{ doc.row.tipo }}
                            </b-table-column>

                            <b-table-column v-slot="doc" label="Data" field="data">
                                {{ doc.row.data }}
                            </b-table-column>

                            <b-table-column v-slot="doc" label="Ações" custom-key="actions" cell-class="is-actions-cell"
                                :width="100">
                                <div class="buttons is-right no-wrap" style="display: flex; justify-content: center;">
                                    <b-tooltip label="Excluir" type="is-light">
                                        <b-button type="is-danger" size="is-small" @click="confirmarDeleteDoc(doc.row)">
                                            <b-icon icon="trash-can" size="is-small" />
                                        </b-button>
                                    </b-tooltip>

                                    <b-tooltip label="Baixar Documento" type="is-light">
                                        <b-button type="is-success" size="is-small" @click="downloadDocumento(doc.row)"
                                            download>
                                            <b-icon icon="download" size="is-small" />
                                        </b-button>
                                    </b-tooltip>
                                </div>
                            </b-table-column>

                            <section slot="empty" class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>
                                        <b-icon icon="emoticon-sad" size="is-large" />
                                    </p>
                                    <p>Não há dados&hellip;</p>
                                </div>
                            </section>
                        </b-table>
                    </b-field>
                </card-component>
            </form>
        </section>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import TitleBar from '@/components/TitleBar.vue'
import HeroBar from '@/components/HeroBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import PessoaService from '@/services/PessoaService.js'
import DocumentoService from '@/services/DocumentoService.js'
import FilePicker from '@/components/FilePicker.vue'
import storage from '@/mixins/storage'


export default defineComponent({
    name: 'FormPessoa',
    components: {
        TitleBar,
        HeroBar,
        CardComponent,
        FilePicker,
    },
    data() {
        return {
            titleStack: ['SECAPI', 'Pessoas', this.getTitle()],
            tipoPessoa: null,
            estados: ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'],
            form: {
                pessoa: {
                    dataInicioAcesso: null
                },
            },
            labelPosition: 'on-border',
            moppCnhFile: null,
            municipios: [],
            empresas: [],
            image: null,
            isLoading: false,
            documentos: [],
            formDocs: {
                file: null,
                data: null,
                tipo: null,
                idPessoa: this.$route.params.id ?? null
            },
            tiposDoc: [
                {
                    "label": "Comprovante de Vacina",
                    "valor": "COMPROVANTE_VACINA"
                },
                {
                    "label": "PROAPI",
                    "valor": "PROAPI"
                },
                {
                    "label": "ASO",
                    "valor": "ASO"
                },
                {
                    "label": "MOPP / CNH Digital",
                    "valor": "CNH_MOPP"
                }
            ],

        }
    },
    mounted() {

        if (this.$route.params.id) {
            this.retrive(this.$route.params.id)
        }


        this.$http.get(`/empresas?page=0&size=1000&orderBy=razaoSocial,ASC`).then((response) => {
            this.empresas = response.data.content;
            this.validacao()
        })

        this.$http.get('/municipios').then((response) => {
            this.municipios = response.data;
        })
    },
    methods: {
        formAction() {
            this.isLoading = true
            if (this.form.pessoa.id) {
                PessoaService.putPessoa(this.form.pessoa.id, this.form.pessoa).then((response) => {
                    this.$buefy.toast.open({
                        message: `Pessoa Atualizada!`,
                        position: 'is-bottom',
                        type: 'is-success'
                    })
                    this.$router.push(`/pessoas?page=1&tipoPessoa=${this.$route.query.tipoPessoa}&orderBy=id,DESC`)
                }).catch((error) => {
                    if (error.response) {
                        this.$buefy.toast.open({
                            message: error.response.data.message ?? `Dados incorretos ou inválidos!`,
                            position: 'is-bottom',
                            type: 'is-danger'
                        })
                        console.log(error.response)
                    }
                }).finally(() => {
                    setTimeout(() => (this.isLoading = false), 2000);
                })
            } else {
                PessoaService.postPessoa(this.form.pessoa)
                    .then((response) => {

                        this.$buefy.toast.open({
                            message: `Pessoa Cadastrada!`,
                            position: 'is-bottom',
                            type: 'is-success'
                        })

                        const documentosPageUrl = `/documentos/${response.data.id}?tipoPessoa=${this.$route.query.tipoPessoa}`;
                        // Redirecione para a página documentos com os parâmetros
                        this.$router.push(documentosPageUrl);
                    })
                    .catch((error) => {
                        if (error.response) {
                            this.$buefy.toast.open({
                                message: error.response.data.message,
                                position: 'is-bottom',
                                type: 'is-danger'
                            })
                            console.log(error.response)
                        }
                    })
                    .finally(() => {
                        setTimeout(() => (this.isLoading = false), 2000);
                    })
            };
        },

        getTitle() {
            var name = this.$route.query.tipoPessoa;
            this.tipoPessoa = name === 'VISITANTE' ? 'Visitante' : name === 'MOTORISTA' ? 'Motorista' : 'Funcionário';
            return this.$route.params.id ? 'Alterar ' + this.tipoPessoa : 'Adicionar ' + this.tipoPessoa;
        },

        async retrive(id) {
            try {
                const response = await PessoaService.getPessoa(id);
                this.form.pessoa = {
                    ...response.data,
                    validadeCnh:  response.data.validadeCnh,
                    dataInicioAcesso: response.data.dataInicioAcesso,
                    dataFimAcesso: response.data.dataFimAcesso,
                    dataDesligamento: response.data.dataDesligamento,
                    idEmpresa: response.data.empresa.id,
                   idMunicipio: response.data?.municipio?.id
                };

                if (this.form.pessoa.status === 'DOCUMENTACAO_PENDENTE') {
                    this.$router.push(`/documentos/${this.$route.params.id}?tipoPessoa=${this.$route.query.tipoPessoa}`)
                } else {
                    DocumentoService.getDocumentosPessoa(id).then((result) => {
                        this.documentos = result.data
                    })

                    if (this.form.pessoa.tipoPessoa === 'MOTORISTA') {

                        DocumentoService.getFoto(this.form.pessoa.foto).then((result) => {
                            const blob = new Blob([result.data])
                            let url = window.URL.createObjectURL(blob);
                            this.image = url
                        })


                    }
                }

            } catch (error) {
                console.error(error);
            }
        },

        validacao() {
            this.form.pessoa.tipoPessoa = this.$route.query.tipoPessoa;

            const justificativa = document.querySelector('[data-js="justificativa"]')
            const dataInicioAcesso = document.querySelector('[data-js="dataInicioAcesso"]')
            const dataFimAcesso = document.querySelector('[data-js="dataFimAcesso"]')
            const cnh = document.querySelector('[data-js="cnh"]')

            switch (this.form.pessoa.tipoPessoa) {
                case 'MOTORISTA':
                    cnh.required = true
                    justificativa.disabled = true
                    justificativa.required = false
                    dataInicioAcesso.disabled = true
                    dataFimAcesso.disabled = true
                    break

                case 'VISITANTE':
                    justificativa.disabled = false
                    justificativa.required = true
                    dataInicioAcesso.disabled = false
                    dataFimAcesso.disabled = false
                    break

                default:
                    justificativa.disabled = true
                    justificativa.required = false
                    dataInicioAcesso.disabled = true
                    dataFimAcesso.disabled = true

            }

            const roleUserLogado = storage.methods.getRole();
            if (roleUserLogado != 'ROLE_CADASTRO' && roleUserLogado != 'ROLE_CONTRATANTE' && roleUserLogado != 'ROLE_ADMIN') {
                const empresaInput = document.querySelector('[data-js="empresa"]')
                empresaInput.disabled = true

                const empresaUserLogado = storage.methods.getEmpresa();

                for (let i = 0; i < this.empresas.length; i++) {
                    if (this.empresas[i].razaoSocial === empresaUserLogado) {
                        this.form.pessoa.idEmpresa = this.empresas[i].id;
                        break;
                    }
                }

            }
        },
        uploadImage() {
            const reader = new FileReader();

            reader.onload = (event) => {
                this.image = event.target.result;
            };

            if (this.form.pessoa.arquivo) {
                reader.readAsDataURL(this.form.pessoa.arquivo);
            }
        },
        voltar() {
            window.history.back();
        },

        confirmarDeleteDoc(item) {
            this.$buefy.dialog.confirm({
                title: 'Excluir Documento ?',
                message: `Tem certeza de que deseja <b>excluir</b> esse documento <b>( ${item.tipo} )</b> ? Essa ação não poderá ser desfeita`,
                confirmText: 'Excluir Documento',
                type: 'is-danger',
                hasIcon: true,
                // this.$buefy.toast.open('Account deleted!')
                onConfirm: () => this.deletarDocumento(item.id)
            })
        },

        deletarDocumento(id) {
            DocumentoService.deleteDocumento(id).then(() => {
                this.$buefy.toast.open({
                    message: `Documento deletado!`,
                    position: 'is-bottom',
                    type: 'is-info'
                })
                location.reload();
            }).catch((error) => {
                this.$buefy.toast.open({
                    message: `Houve algum problema ao deletar o documento`,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                console.error(error)
            })
        },


        async formDocsAction() {

            if (!this.validarDoc()) {
                return
            }

            try {
                this.isLoading = true

                await DocumentoService.postDocumento(this.formDocs)


                this.$buefy.toast.open({
                    message: 'Documentos Enviado com Sucesso!',
                    position: 'is-bottom',
                    type: 'is-success'
                })


                location.reload();
            } catch (error) {
                this.$buefy.toast.open({
                    message: error.response.data.message ?? 'Erro ao enviar documento!',
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                console.error('Erro ao enviar documentos:', error);
            } finally {
                this.isLoading = false;
            }
        },

        validarDoc() {
            if (!this.formDocs.data) {
                this.$buefy.toast.open({
                    message: 'Erro, por favor preencha a Data corretamente!',
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                return false
            } else {
                if (this.formDocs.data > new Date()) {
                    this.$buefy.toast.open({
                        message: 'Erro, Data informada maior que data atual',
                        position: 'is-bottom',
                        type: 'is-danger'
                    })
                    return false
                }
            }

            if (this.form.pessoa.tipoPessoa === 'VISITANTE' && this.formDocs.tipo != 'COMPROVANTE_VACINA') {
                this.$buefy.toast.open({
                    message: 'Visitante só pode incluir "Comprovante de Vacina"!',
                    position: 'is-bottom',
                    type: 'is-warning'
                })
                return false
            }


            return true
        },

        downloadDocumento(item) {
            DocumentoService.baixarDoc(item.nomeArquivo).then((result) => {
                const blob = new Blob([result.data])
                let url = window.URL.createObjectURL(blob);

                let extensao = item.nomeArquivo.split('.').pop()
                let nomeDoc = `doc-${item.tipo}.${extensao}`

                const link = document.createElement("a");
                link.href = url;
                link.download = nomeDoc;
                link.click();

                window.URL.revokeObjectURL(url);
            })
        }

    },
    computed: {
        getEmpresas() {
            return this.empresas.map((empresa) => {
                return {
                    "razaoSocial": empresa.razaoSocial,
                    "id": empresa.id
                }
            })
        },

        getMunicipios() {
            return this.municipios.filter((municipio) => {
                if (municipio.uf === this.form.pessoa.uf) {
                    return {
                        "codigo": municipio.codigo,
                        "nome": municipio.nome,
                        "uf": municipio.uf,
                        "id": municipio.id
                    }
                }
            })
        },
        status() {
            return [
                {value: 'PENDENTE', label: 'Pendente'},
                {value: 'APROVADO', label: 'Aprovado'},
                {value: 'RECUSADO', label: 'Recusado'},
                {value: 'BLOQUEADO', label: 'Bloqueado'},
                {value: 'CADASTRADO', label: 'Cadastrado'},
                {value: 'PENDENTE_REMOCAO', label: 'Pendente Remoção'},
            ]
        }

    }
})
</script>