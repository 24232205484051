<template>
    <div>
        <title-bar :title-stack="titleStack" />
        <div class="card">
            <header class="card-header">
                <p class="card-header-title"><span class="icon"><i class="mdi mdi-ballot default"></i></span> Listar
                    Agendamentos </p>
                <router-link slot="right" :to="`/formAutorizacao?tipo=${$route.query.tipo}`" class="button"
                    style="margin-right: 1.5rem; margin-top: 1rem; margin-bottom: 1rem;">
                    Adicionar
                </router-link>
            </header>

            <div class="card-content">
                <form @submit.prevent="buscar()">
                    <b-field horizontal style="margin-left: -100px;">
                        <b-field label="Placa">
                            <b-input v-model="filtro.placa" icon="card-bulleted-outline" placeholder="Digite a placa"
                                name="placa" />
                        </b-field>

                        <b-field label="Documento anexado?" >
                            <b-select v-model="filtro.temDocumentoFiscal" expanded>
                                <option value="true">SIM</option>
                                <option value="false">NÃO</option>
                            </b-select>
                        </b-field>

                        <b-field label="Terminal">
                            <b-select v-model="filtro.terminal" placeholder="Selecione o Destino*" expanded>
                                <option v-for="item in getDestinos" :key="item.id" :value="item.id">
                                    {{ item.razaoSocial }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field grouped>
                            <div class="control" style="margin-top: 32px;">
                                <b-button native-type="submit" type="is-info">
                                    Buscar
                                </b-button>
                            </div>
                        </b-field>
                    </b-field>
                </form>
            </div>

            <section class="section is-main-section">
                <card-component class="has-table has-mobile-sort-spaced">
                    <b-table striped hoverable :data="getResultTable">

                        <b-table-column label="Placa" field="placa" v-slot="item">
                            {{ item.row.placa }}
                        </b-table-column>

                        <b-table-column label="Criação Estadia" field="criacao" v-slot="item">
                            {{ $util.formatarData(item.row.dataSolicitacao) }}
                        </b-table-column>

                        <b-table-column label="Destino" field="destino" v-slot="item">
                            {{ item.row.destino }}
                        </b-table-column>

                        <b-table-column label="Entrada PRC" field="entrada" v-slot="item">
                            {{ $util.formatarData(item.row.entrada) }}
                        </b-table-column>

                        <b-table-column label="Solicitado" field="solicitado" v-slot="item">
                            {{ $util.formatarData(item.row.dataSolicitacao) }}
                        </b-table-column>

                        <b-table-column label="Saída PRC" field="saida" v-slot="item">
                            {{ $util.formatarData(item.row.saida) }}
                        </b-table-column>

                        <b-table-column label="Documento informado?" field="temDocumentoFiscal" v-slot="item">
                            <span class="tag" :class="item.row.temDocumentoFiscal ? 'is-success' : 'is-info'">
                                {{ item.row.temDocumentoFiscal ? 'SIM' : 'NÃO'}}
                            </span>
                        </b-table-column>

                        <b-table-column label="Recebido" field="recebido" v-slot="item">
                            <span class="tag" :class="item.row.recebido ? 'is-success' : 'is-info'">
                                {{ item.row.recebido ? 'SIM' : 'NÃO' }}
                            </span>
                        </b-table-column>

                        <b-table-column v-slot="item" label="Ações" custom-key="actions" centered
                            cell-class="is-actions-cell">

                            <div class="buttons no-wrap is-centered">
                                <b-dropdown aria-role="list" position="is-bottom-left" append-to-body>
                                    <template #trigger="{ active }">
                                        <b-button :type="!active ? 'is-primary' : 'is-small'"
                                            :icon-right="active ? 'menu-up' : 'menu-down'" size="is-small" />
                                    </template>


                                    <b-dropdown-item aria-role="listitem">
                                        <router-link
                                            :to="{ name: 'FormAgendamento.edit', params: { id: item.row.id }, query: $route.query }"
                                            class="media has-text-dark">
                                            <b-icon class="media-left" icon="eye"></b-icon>
                                            <div class="media-content">
                                                <h3>Visualizar / Anexar Documento</h3>
                                            </div>
                                        </router-link>
                                    </b-dropdown-item>

                                    <b-dropdown-item v-if="!item.row.recebido" v-show="permitirAlterar('PADRAO')">
                                        <div class="media" @click.prevent="dialogReceber(item.row)">
                                            <b-icon class="media-left" icon="check"></b-icon>
                                            <div class="media-content">
                                                <h3>Recebido</h3>
                                            </div>
                                        </div>
                                    </b-dropdown-item>

                                    <b-dropdown-item v-show="permitirAlterar('ENTRADA')">
                                        <div class="media" @click.prevent="dialogEntrada(item.row)">
                                            <b-icon class="media-left" icon="chevron-up-circle"></b-icon>
                                            <div class="media-content">
                                                <h3>Dar Entrada</h3>
                                            </div>
                                        </div>
                                    </b-dropdown-item>

                                    <b-dropdown-item v-show="permitirAlterar('PADRAO')">
                                        <div class="media" @click.prevent="dialogCancelar(item.row)">
                                            <b-icon class="media-left" icon="close"></b-icon>
                                            <div class="media-content">
                                                <h3>Cancelar</h3>
                                            </div>
                                        </div>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>

                        </b-table-column>

                        <section slot="empty" class="section">
                            <div class="content has-text-grey has-text-centered">
                                <p>
                                    <b-icon icon="emoticon-sad" size="is-large" />
                                </p>
                                <p>Não há dados&hellip;</p>
                            </div>
                        </section>
                    </b-table>
                    <br>
                    <section style="margin:15px;">
                        <b-pagination :total="pageable.size" v-model="pageable.current" :range-before="2" :range-after="2"
                            :per-page="15" :page-input="false">

                            <template #default="props">
                                <b-pagination-button :page="props.page" :id="`page${props.page.number}`" tag="router-link"
                                    :to="`/autorizacoes?page=${props.page.number}&${$util.formatQueryUrl($route.query)}`">
                                    {{ props.page.number }}
                                </b-pagination-button>
                            </template>


                            <template #previous="props">
                                <b-pagination-button :page="props.page" tag="router-link"
                                    :to="`/autorizacoes?page=${props.page.number}&${$util.formatQueryUrl($route.query)}`">
                                    Anterior
                                </b-pagination-button>
                            </template>

                            <template #next="props">
                                <b-pagination-button :page="props.page" tag="router-link"
                                    :to="`/autorizacoes?page=${props.page.number}&${$util.formatQueryUrl($route.query)}`">
                                    Próximo
                                </b-pagination-button>
                            </template>
                        </b-pagination>
                        <template>
                            <div>
                                <b>Total de Registros: </b> {{ pageable.size }}
                            </div>
                        </template>
                    </section>
                </card-component>
            </section>
        </div>
    </div>
</template>

<style></style>

<script>
import { defineComponent } from 'vue'
import TitleBar from '@/components/TitleBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import AutorizacoesService from '@/services/AutorizacoesService'
import storage from '@/mixins/storage'

export default defineComponent({
    name: 'ListAutorizacao',
    components: {
        TitleBar,
        CardComponent,
    },
    data() {
        return {
            titleStack: ['SECAPI', 'Agendamentos', 'Listar Agendamentos'],
            filtro: {
                tipo: this.$route.query.tipo ?? null,
            },
            pageable: {
                current: 1,
                size: 0,
            },
            totalElements: null,
            cte: null,
            terminais: [],
            resultList: []
        }
    },
    methods: {
        buscar() {
            if (this.filtro) {
                this.pageable.current = 1
                let queryFiltro = `agendamentos?page=${this.pageable.current}${this.$util.formatQueryUrl(this.filtro)}&orderBy=id,DESC`
                this.$router.push(queryFiltro).catch(() => { })
            }
        },

        dialogCancelar(item) {
            this.$buefy.dialog.confirm({
                title: 'Excluir Autorização',
                message: 'Tem certeza que deseja <b>cancelar</b> esta autorização ?',
                confirmText: 'Sim',
                cancelText: 'Não',
                type: 'is-danger',
                hasIcon: true,
                size: 'is-medium',
                onConfirm: () => this.cancelarAutorizacao(item.id)
            })
        },

        cancelarAutorizacao(id) {
            AutorizacoesService.cancelarAutorizacao(id).then(() => {
                this.$buefy.toast.open({
                    message: `Autorização cancelada!`,
                    position: 'is-bottom',
                    type: 'is-info'
                })
                location.reload();
            }).catch((error) => {
                this.$buefy.toast.open({
                    message: `Houve algum Problema ao cancelar`,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                console.error(error.response.data.message)
            })
        },

        dialogReceber(item) {
            this.$buefy.dialog.confirm({
                title: 'Receber Autorização',
                message: 'Deseja <b>confirmar</b> o recebimento ?',
                confirmText: 'Sim',
                cancelText: 'Não',
                type: 'is-success',
                hasIcon: true,
                size: 'is-medium',
                onConfirm: () => this.receberAutorizacao(item.id)
            })
        },

        receberAutorizacao(id) {
            AutorizacoesService.receberAutorizacao(id).then(() => {
                this.$buefy.toast.open({
                    message: `Autorização Recebida!`,
                    position: 'is-bottom',
                    type: 'is-info'
                })
                location.reload();
            }).catch((error) => {
                this.$buefy.toast.open({
                    message: `Houve algum Problema ao receber`,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                console.error(error)
            })
        },

        dialogEntrada(item) {
            this.$buefy.dialog.confirm({
                title: 'Dar Entrada Autorização',
                message: 'Deseja dar <b>entrada</b> na autorização ?',
                confirmText: 'Sim',
                cancelText: 'Não',
                type: 'is-warning',
                hasIcon: true,
                size: 'is-medium',
                onConfirm: () => this.darEntradaAutorizacao(item.id)
            })
        },

        darEntradaAutorizacao(id) {
            AutorizacoesService.darEntradaAutorizacao(id).then(() => {
                this.$buefy.toast.open({
                    message: `Entrada realizada com sucesso!`,
                    position: 'is-bottom',
                    type: 'is-info'
                })
                location.reload();
            }).catch((error) => {
                this.$buefy.toast.open({
                    message: error.response.data.message ? error.response.data.message : `Houve algum problema ao dar Entrada na autorização`,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                console.error(error)
            })
        },

        permitirAlterar(action) {
            const listaRolePermitida = ['ROLE_ADMIN', 'ROLE_TERMINAL', 'ROLE_PATIO_TRIAGEM']
            const role = storage.methods.getRole();
             switch (action) {
                case 'PADRAO':
                    return listaRolePermitida.includes(role);
                
                case 'ENTRADA':
                    return role === 'ROLE_ADMIN' || role === 'ROLE_VIGILANTE'
                default:
                    return false;
             }
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal.query) {
                    this.pageable.current = parseInt(newVal.query.page)

                    AutorizacoesService.getAutorizacao(this.pageable.current - 1, this.$util.formatQueryUrl(this.$route.query)).then((result) => {
                        this.resultList = result.data.content;
                        this.pageable.current = result.data.number + 1;
                        this.pageable.size = result.data.totalElements;
                    })

                    this.filtro = {
                        tipo: this.$route.query.tipo
                    }
                }
            }
        }
    },
    mounted() {
        this.$http.get(`/empresas?page=0&tipo=TERMINAL`).then((response) => {
            this.terminais = response.data.content;

        })
    },
    computed: {
        getDestinos() {
            return this.terminais.map((destino) => {
                return {
                    "razaoSocial": destino.razaoSocial,
                    "id": destino.id
                }
            })
        },

        getResultTable() {
            return this.resultList.map((item) => {
                return {
                    "id": item.id,
                    "placa": item.placa,
                    "inicio": item.inicio,
                    "status": item.status,
                    "entrada": item.entrada,
                    "saida": item.saida,
                    "temDocumentoFiscal": item.temDocumentoFiscal,
                    "recebido": item.recebido,
                    "dataSolicitacao": item.dataSolicitacao,
                    "destino": item.terminal.razaoSocial
                }
            })
        }
    },
})
</script>