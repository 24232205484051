import Vue from 'vue';

export default {
    postAutorizacao(form) {
        form.inicio = Vue.prototype.$util.formatarData(form.inicio);
        return Vue.prototype.$http.post('/agendamentos', form);
    },
    getAutorizacao(page, filtro) {
        if (page == undefined) {
            page = 0
        }
        var request = `agendamentos?page=${page}`
        if(filtro){
            request += `${filtro}`
        }
        return Vue.prototype.$http.get(request);
    },
    cancelarAutorizacao(id){
        return Vue.prototype.$http.put(`/agendamentos/${id}/cancelar`);
    },
    getAutorizacaoById(id){
        return Vue.prototype.$http.get(`/agendamentos/${id}`);
    },
    informarCte(id, formData){
        const configHeader = {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
          };
        return Vue.prototype.$http.post(`/agendamentos/${id}/cte`, formData, configHeader);
    },
    informarNotaFiscal(id, formData){
        const configHeader = {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
          };
        return Vue.prototype.$http.post(`/agendamentos/${id}/nfe`, formData, configHeader);
    },
    receberAutorizacao(id){
        return Vue.prototype.$http.put(`/agendamentos/${id}/receber`);
    },
    darEntradaAutorizacao(id){
        return Vue.prototype.$http.put(`/agendamentos/${id}/entrada`);
    }
}