<template>
    <div class="box">
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
        <b-tabs type="is-toggle" expanded size="is-small" v-model="activeTab" :multiline="true">
            <template v-for="menu in listMenu">
                <b-tab-item :key="menu.value" :label="menu.label" :icon="menu.icon" :value="menu.value">


                    <card-component class="has-table has-mobile-sort-spaced">
                        <h1 class="title is-5" style="padding: 0.5rem; margin: 0;"> Lista de Recursos de {{ menu.label }}
                        </h1>
                        <b-table striped hoverable :data="getRecursosMap" class="table is-bordered">
                            <b-table-column label="ID" field="id" v-slot="item">
                                {{ item.row.id }}
                            </b-table-column>

                            <b-table-column label="Descrição" field="descricao" v-slot="item">
                                {{ item.row.descricao }}
                            </b-table-column>

                            <b-table-column label="Rota" field="rota" v-slot="item">
                                {{ item.row.rota }}
                            </b-table-column>

                            <b-table-column label="Método" field="metodo" v-slot="item">
                                <span class="tag" :class="estiloMetodo(item.row.metodo)">
                                    {{ item.row.metodo }}
                                </span>
                            </b-table-column>

                            <b-table-column label="Autorizado ?" field="acao" v-slot="item" custom-key="actions" centered
                                cell-class="is-actions-cell">

                                <div class="buttons is-centered no-wrap">
                                    <b-tooltip label="Autorizar ?" type="is-light ">
                                        <b-switch type="is-success" passive-type="is-danger" :rounded="false"
                                            @input="mudarAutorizacao(item.row)" v-model="item.row.autorizacao">
                                        </b-switch>
                                    </b-tooltip>
                                </div>
                            </b-table-column>

                            <section slot="empty" class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>
                                        <b-icon icon="emoticon-sad" size="is-large" />
                                    </p>
                                    <p>Não há dados&hellip;</p>
                                </div>
                            </section>
                        </b-table>

                    </card-component>
                </b-tab-item>
            </template>
        </b-tabs>

    </div>
</template>
  




<script>
import { defineComponent } from 'vue'
import CardComponent from '@/components/CardComponent.vue'
import RecursosService from '@/services/RecursosService'
import RoleService from '@/services/RoleService'

export default defineComponent({
    name: 'ListRecursos',
    components: { CardComponent },
    data() {
        return {
            activeTab: 'VEICULOS',
            listMenu: [
                { label: 'Veículos', value: 'VEICULOS', icon: 'truck-outline' },
                { label: 'Pessoas', value: 'PESSOAS', icon: 'account-multiple' },
                { label: 'Empresas', value: 'EMPRESAS', icon: 'city-variant-outline' },
                { label: 'Agendamentos', value: 'AGENDAMENTOS', icon: 'lock' },
                { label: 'Configurações', value: 'CONFIGURACOES', icon: 'settings' }
            ],
            resultList: [],
            listaRecursosAtivos: [],
            idRole: this.$route.params.id ?? null,
            isLoading: false
        }
    },
    methods: {
        buscar() {
            RecursosService.getRecursos(0, `&menu=${this.activeTab}`)
                .then((result) => {
                    this.resultList = result.data.content
                    console.log(this.resultList)
                })
                .catch((error) => {
                    console.error(error.response)
                    this.$buefy.toast.open({
                        message: 'Não foi possível recuperar a lista de Recursos!',
                        type: 'is-danger',
                        position: 'is-bottom',
                    })
                })

            if (this.idRole) {
                RoleService.getRole(this.idRole).then((result) => {
                    this.listaRecursosAtivos = result.data.recursos
                })
            }
        },

        estiloMetodo(metodo) {
            switch (metodo) {
                case 'POST':
                    return 'is-success'
                case 'GET':
                    return 'is-link'
                case 'DELETE':
                    return 'is-danger'
                case 'PUT':
                    return 'is-warning'
            }
        },

        mudarAutorizacao(item) {
            if (this.idRole) {
                item.autorizacao ? this.adicionarRecurso(item.id) : this.removerRecurso(item.id);
            } else {
                this.$buefy.toast.open({
                    message: 'Não foi possível completar a operação!',
                    type: 'is-danger',
                    position: 'is-bottom'
                })
            }

        },

        adicionarRecurso(idRecurso) {
            this.isLoading = true
            RoleService.adicionarRecurso(this.idRole, idRecurso)
                .then((result) => {
                    this.listaRecursosAtivos = result.data.recursos
                    this.$buefy.toast.open({
                        message: 'Autorização Concedida!',
                        type: 'is-success',
                        position: 'is-bottom'
                    })

                })
                .catch((error) => {
                    this.$buefy.toast.open({
                        message: 'Não foi possível alterar a autorização!',
                        type: 'is-danger',
                        position: 'is-bottom'
                    })
                })
                .finally(() => {
                    setTimeout(() => (this.isLoading = false), 500);
                })
        },

        removerRecurso(idRecurso) {
            this.isLoading = true
            RoleService.removerRecurso(this.idRole, idRecurso)
                .then((result) => {
                    this.listaRecursosAtivos = result.data.recursos
                    this.$buefy.toast.open({
                        message: 'Autorização Removida!',
                        type: 'is-success',
                        position: 'is-bottom'
                    })

                })
                .catch((error) => {
                    this.$buefy.toast.open({
                        message: 'Não foi possível alterar a autorização!',
                        type: 'is-danger',
                        position: 'is-bottom'
                    })
                })
                .finally(() => {
                    setTimeout(() => (this.isLoading = false), 500);
                })
            setTimeout(() => (this.isLoading = false), 500);
        }
    },
    mounted() {
        this.buscar();
    },
    watch: {
        activeTab: {
            immediate: true,

            handler(newVal, oldVal) {
                this.buscar()
            }
        }
    },
    computed: {
        getRecursosMap() {
            return this.resultList.map((item) => {
                const estaAtivo = this.listaRecursosAtivos.some((ativo) => ativo.id === item.id);
                console.log(estaAtivo)
                return {
                    "id": item.id,
                    "descricao": item.descricao,
                    "rota": item.rota,
                    "metodo": item.metodo,
                    "autorizacao": estaAtivo
                }
            })
        }
    }
})
</script>