<template>
    <div>
        <title-bar :title-stack="titleStack" />
        <hero-bar>
            Formulário
            <router-link slot="right" to="/" class="button">
                Dashboard
            </router-link>
        </hero-bar>
        <form @submit.prevent="formAction" class="box">
            <section class="section is-main-section">
                <card-component :title="title" icon="ballot">
                    <b-field label="Dados da Solicitação" horizontal>
                        <b-field label="Solicitante*" label-position="on-border">
                            <b-input v-model="form.autorizacao.usuarioSolicitacao" icon="account-circle-outline"
                                placeholder="Solicitante" name="solicitante" required disabled></b-input>
                        </b-field>
                        <b-field label="Data Solicitação*" label-position="on-border">
                            <b-datepicker v-model="form.autorizacao.dataSolicitacao"
                                placeholder="Selecione data e hora de início..." icon="calendar-today" trap-focus disabled>
                            </b-datepicker>
                        </b-field>
                        <b-field label="Placa do Veículo*" label-position="on-border">
                            <b-input v-model="form.autorizacao.placa" icon="card-bulleted-outline"
                                placeholder="Placa do Veículo" name="placa" required :disabled="readonly"></b-input>
                        </b-field>
                    </b-field>
                    <b-field label="Dados do Agendamento" horizontal>
                        <b-field label="Destino*" label-position="on-border">
                            <b-select v-model="form.autorizacao.terminalId" placeholder="Selecione o Destino*" required
                                expanded :disabled="readonly">
                                <option v-for="item in getDestinos" :key="item.id" :value="item.id">
                                    {{ item.razaoSocial }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-field label="tipo de Operação*" label-position="on-border">
                            <b-select placeholder="Tipo de Operação*" required
                                expanded :disabled="readonly" v-model="form.autorizacao.tipoOperacao">
                                <option v-for="tipo in tipoAgendamento" :key="tipo.value" :value="tipo.value">
                                    {{ tipo.label }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-field label="Tipo de Agendamento*" label-position="on-border">
                            <b-select v-model="form.autorizacao.tipo" placeholder="Selecione o Tipo de Autorização*"
                                required expanded disabled>
                                <option v-for="tipo in tipoAutorizacao" :key="tipo.value" :value="tipo.value">
                                    {{ tipo.label }}
                                </option>
                            </b-select>
                        </b-field>
                    </b-field>
                    <b-field horizontal>
                        <b-field label="Transportadora responsável*" label-position="on-border">
                            <b-select expanded :disabled="readonly" v-model="form.autorizacao.cnpjTransportadora">
                                <option v-for="item in getTransportadoras" :key="item.id" :value="item.cnpjTransportadora">
                                    {{ item.nomeTransportadora }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-field label="Motorista*" label-position="on-border">
                            <b-select required expanded :disabled="readonly" v-model="form.autorizacao.cnhMotorista">
                                <option v-for="item in getMotoristas" :key="item.id" :value="item.cnhMotorista">
                                    {{ item.nomeMotorista }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-field label="Data de Início*" label-position="on-border"
                            message="Necessário informar data e hora.">
                            <b-datetimepicker v-model="form.autorizacao.inicio" placeholder="Data Inicio Acesso"
                                icon="calendar-today" trap-focus :disabled="readonly">
                            </b-datetimepicker>
                        </b-field>
                    </b-field>
                    <b-field horizontal>
                        <b-field label="Data de Fim*" label-position="on-border" style="max-width: 250px;">
                            <b-datepicker v-model="form.autorizacao.fim" placeholder="Data Fim Acesso" icon="calendar-today"
                                trap-focus disabled>
                            </b-datepicker>
                        </b-field>
                    </b-field>
                </card-component>
                <b-field horizontal v-if="!readonly">
                <b-field grouped>
                    <div class="control">
                        <b-button native-type="submit" type="is-info" :loading="isLoading">
                            Salvar
                        </b-button>
                    </div>
                </b-field>
            </b-field>  
        </section>


        <div style="display: flex; justify-content: center; width: 100%; gap: 15px;">
            <b-field label="Selecione o tipo de documento:" v-if="readonly" v-model="form.autorizacao.tipoDocumentoFiscal">
                    <b-radio v-model="tipoDocumento" native-value="cte" checked>CTe</b-radio>
                    <b-radio v-model="tipoDocumento" native-value="notaFiscal">Nota Fiscal</b-radio>
            </b-field>
        </div>


            <section class="section is-main-section" v-if="tipoDocumento === 'cte'">
                    <card-component title="Anexar CTe" icon="ballot">
                        <div class="row" style="display: flex; gap: 45px; padding-left: 80px;">
                            <b-field class="file is-primary">
                                <b-upload v-model="form.autorizacao.cte" class="file-label" accept=".xml">
                                    <span class="file-cta" style="margin-left: 200px;">
                                        <b-icon class="file-icon" icon="upload"></b-icon>
                                        <span class="file-label">faça o upload do CTe</span>
                                    </span>
                                </b-upload>
                            </b-field>
                            <b-field label="Codigo CTe" label-position="on-border" class="codigoCte" style=" width: 300px;" >
                                <b-input placeholder="Codigo CTe" v-model="cte.chaveCte">
                                </b-input>
                            </b-field>
                            <b-field label="Unidade de Medida*" label-position="on-border" style="width: 300px;">
                                <b-select expanded v-model="cte.tipoMedida">
                                    <option v-for="item in tipoMedida" :key="item.value" :value="item.value" >
                                        {{ item.label }}
                                    </option>
                                </b-select>
                            </b-field>
                            <b-field label="Peso ou volume da carga" label-position="on-border" class="pesoVolume" style=" width: 330px;">
                                <b-input placeholder="Peso ou volume da carga" v-model="cte.quantidade"></b-input>
                            </b-field>
                            
                        </div>

                        <b-field label="Endereço Origem" horizontal>
                            <b-field label="Estado" label-position="on-border">
                            <b-select v-model="cte.ufOrigem" placeholder="Selecione o estado" expanded>
                                    <option v-for="(estado, index) in estados" :key="index" :value="estado" >
                                        {{ estado }}
                                    </option>
                            </b-select>
                            </b-field>
                            <b-field label="Município" label-position="on-border">
                                <b-select v-model="cte.municipioOrigem" placeholder="Selecione um municipio"
                                    expanded >
                                    <option v-for="municipio in getMunicipiosOrigem" :key="municipio.id" :value="municipio.nome">
                                        {{ municipio.nome }}
                                    </option>
                                </b-select>
                            </b-field>
                        </b-field>
                        <b-field label="Endereço Destino" horizontal>
                            <b-field label="Estado" label-position="on-border">
                            <b-select v-model="cte.ufDestino" placeholder="Selecione o estado" expanded>
                                    <option v-for="(estado, index) in estados" :key="index" :value="estado">
                                        {{ estado }}
                                    </option>
                            </b-select>
                            </b-field>
                            <b-field label="Município" label-position="on-border">
                                <b-select v-model="cte.municipioDestino" placeholder="Selecione um municipio"
                                    expanded>
                                    <option v-for="municipio in getMunicipiosDestino" :key="municipio.id" :value="municipio.nome">
                                        {{ municipio.nome }}
                                    </option>
                                </b-select>
                            </b-field>
                        </b-field>
                        <hr>         
                        <b-field horizontal v-if="readonly">
                            <b-field grouped>
                                <div class="control">
                                    <b-button type="is-info" :loading="isLoading"  @click="enviarCte()">
                                        Salvar
                                    </b-button>
                                </div>
                            </b-field>
                        </b-field>
                    </card-component>
            </section>

            <section class="section is-main-section" v-if="tipoDocumento === 'notaFiscal'">
                    <card-component title="Anexar Nota fiscal" icon="ballot">
                        <div style="display: flex; gap: 22px; padding-left: 95px;">
                            <b-field class="file is-primary">
                                <b-upload v-model="nfse.notafiscal" class="file-label" accept=".xml" >
                                    <span class="file-cta" style="margin-left: 163px;">
                                        <b-icon class="file-icon" icon="upload"></b-icon>
                                        <span class="file-label">faça o upload da nota fiscal</span>
                                    </span>
                                </b-upload>
                            </b-field>
                        
                        
                            <b-field label="Número" label-position="on-border" style=" width: 300px;" >
                                    <b-input placeholder="Número" v-model="nfse.numero"></b-input>
                            </b-field>
                            <b-field label="Valor da nota" label-position="on-border" style=" width: 300px;" >
                                    <b-input placeholder="R$" v-model="nfse.valor"></b-input>
                            </b-field>
                        </div>
                        <b-field label="Prestador" style="display: flex; gap: 25px; padding-left: 162px">
                            
                                <b-field label="Nome" label-position="on-border" style=" width: 300px; padding-right: 20px;" >
                                    <b-input placeholder="Nome / RazãoSocial" v-model="nfse.nomePrestador"></b-input>
                                </b-field>
                            
                                <b-field label="Documento" label-position="on-border" style=" width: 300px;" >
                                    <b-input placeholder="CPF / CNPJ" v-model="nfse.documentoPrestador" v-mask="'##.###.###/####-##'"></b-input>
                                </b-field>
                            
                        </b-field>
                        <b-field label="Tomador" style="display: flex; gap: 25px; padding-left: 170px">
                            <b-field label="Nome" label-position="on-border" style=" width: 300px; padding-right: 20px;" >
                                    <b-input placeholder="Nome / RazãoSocial" v-model="nfse.nomeTomador"></b-input>
                                </b-field>
                            
                                <b-field label="Documento" label-position="on-border" style=" width: 300px;" >
                                    <b-input placeholder="CPF / CNPJ" v-model="nfse.documentoTomador" v-mask="'##.###.###/####-##'"></b-input>
                                </b-field>
                            </b-field>
                        <hr>         
                        <b-field horizontal v-if="readonly">
                            <b-field grouped>
                                <div class="control">
                                    <b-button native-type="submit" type="is-info" :loading="isLoading"  @click="enviarNota()">
                                        Salvar
                                    </b-button>
                                </div>
                            </b-field>
                        </b-field>
                    </card-component>
            </section>   
        </form>


        
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import HeroBar from '@/components/HeroBar.vue';
import TitleBar from '@/components/TitleBar.vue';
import CardComponent from '@/components/CardComponent.vue';
import storage from '@/mixins/storage';
import AutorizacoesService from '@/services/AutorizacoesService.js';

export default defineComponent({
    name: 'AdicionarAutorizacao',
    components: {
        HeroBar,
        TitleBar,
        CardComponent,
    },
    data() {
        return {
            titleStack: ['SECAPI', 'Agendamentos', this.$route.params.id ? 'Visualizar Agendamento' : 'Adicionar Agendamento'],
            title: this.$route.params.id ? 'Visualizar Agendamento' : 'Adicionar Agendamento',  
            isLoading: false,
            estados: ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'],
            form: {
                autorizacao: { 
                    id: null,
                    autorizacao: null,   
                    usuarioSolicitacao: null,
                    tipoOperacao: '',
                    tipoDocumentoFiscal: '',
                },
            },

            cte: {
                cte: null,
                chaveCte: '',
                quantidade: '',
                tipoMedida: '',
                ufOrigem: '', municipioOrigem: '',
                ufDestino: '', municipioDestino: ''
                },

            nfse: {
                notafiscal: null,
                numero: '',
                codigoVerificacao: '',
                nomePrestador: '',
                documentoPrestador: '',
                nomeTomador: '',
                documentoTomador: '',
                valor: '',
            }, 

            tipoAutorizacao: [
                { label: 'Grão', value: 'GRAO' },
                { label: 'Tanque', value: 'TANQUE' }
            ],
            tipoAgendamento: [
                { label: 'Carregar', value: 'CARREGAR'},
                { label: 'Descarregar', value: 'DESCARREGAR'}
            ],
            tipoMedida: [
                {label: 'M3', value: 'M3'},
                {label: 'KG', value: 'KG'},
                {label: 'TON', value: 'TON'},
                {label: 'UNIDADE', value: 'UNIDADE'},
                {label: 'LT', value: 'LT'},
            ],

            municipios: [],
            tipoDocumento: 'notaFiscal',
            destinos: [],
            readonly: false,
            motoristas: [],
            transportadoras: [],
        }

    },
    mounted() {
        if (this.$route.params.id) {
            this.readonly = true
            this.retrive(this.$route.params.id);
        } else {
            this.form.autorizacao.usuarioSolicitacao = storage.methods.getNome()
            this.form.autorizacao.dataSolicitacao = new Date()
            this.form.autorizacao.tipo = this.$route.query.tipo
        }

        this.$http.get(`/empresas?page=0&tipo=TERMINAL`).then((response) => {
            this.destinos = response.data.content;

        }),

        this.$http.get(`/pessoas?page=0&tipoPessoa=MOTORISTA`).then((response) => {
            this.motoristas = response.data.content;

        }),

        this.$http.get(`/empresas?page=0&tipo=TRANSPORTADORA`).then((response) => {
            this.transportadoras = response.data.content;

        }),

        this.$http.get('/municipios').then((response) => {
            this.municipios = response.data;
        })
    },
    computed: {
        getDestinos() {
            return this.destinos.map((destino) => {
                return {
                    "razaoSocial": destino.razaoSocial,
                    "id": destino.id
                }
            })
        },

        getMotoristas(){
            return this.motoristas.map((motorista) => {
                return {
                    "nomeMotorista": motorista.nome,
                    "cnhMotorista": motorista.cnh
                }
            })
        },

        getTransportadoras(){
            return this.transportadoras.map((transportadora) => {
                return {
                    "nomeTransportadora": transportadora.razaoSocial,
                    "cnpjTransportadora": transportadora.cnpj
                }
            })
        },
        getMunicipiosOrigem() {
            return this.municipios.filter((municipio) => {
                if (municipio.uf === this.cte.ufOrigem) {
                    return {
                        "codigo": municipio.codigo,
                        "nome": municipio.nome,
                        "uf": municipio.uf,
                        "id": municipio.id
                    }
                }
            })
        },
        getMunicipiosDestino() {
            return this.municipios.filter((municipio) => {
                if (municipio.uf === this.cte.ufDestino) {
                    return {
                        "codigo": municipio.codigo,
                        "nome": municipio.nome,
                        "uf": municipio.uf,
                        "id": municipio.id
                    }
                }
            })
        },
    },
    methods: {
        formAction() {
            if (!this.form.autorizacao.id) {
                this.isLoading = true
                AutorizacoesService.postAutorizacao(this.form.autorizacao)
                    .then((response) => {
                        setTimeout(() => {
                            this.$buefy.toast.open({
                                message: 'Solicitação Concluída!',
                                position: 'is-bottom',
                                type: 'is-success'
                            })
                        })
                        this.$router.push(`/agendamentos?page=1&tipo=${this.$route.query.tipo}&orderBy=id,DESC`)
                    })
                    .catch((error) => {
                        if (error.response) {
                            this.$buefy.toast.open({
                                message: error.response.data.message,
                                position: 'is-bottom',
                                type: 'is-danger'
                            })
                        }
                    })
                    .finally(() => {
                        setTimeout(() => (this.isLoading = false), 2000)
                        this.form.autorizacao.inicio = null
                    })
            }
        },
        retrive(id) {
            AutorizacoesService.getAutorizacaoById(id).then((result) => {
                this.form.autorizacao= {
                    ...result.data,
                    usuarioSolicitacao: result.data.usuarioSolicitacao.nome,
                    terminalId: result.data.terminal.id,
                    tipoOperacao: result.data.tipoOperacao,
                    cnhMotorista: result.data.motorista.cnh,
                    cnpjTransportadora: result.data.transportadora.cnpj,
                    inicio: this.$util.converterDataBanco(result.data.inicio),
                    fim: this.$util.converterDataBanco(result.data.fim),
                    dataSolicitacao: this.$util.converterDataBanco(result.data.dataSolicitacao),        
                }
                if (result.data.cte) {
                    this.cte= {
                    ...result.data,
                    chaveCte: result.data.cte.chave,
                    tipoMedida: result.data.cte.infoCargas[0].cunid,
                    quantidade: result.data.cte.infoCargas[0].qcarga,
                    ufOrigem: result.data.cte.origem.uf,
                    municipioOrigem: result.data.cte.origem.xmun,
                    ufDestino: result.data.cte.destino.uf,
                    municipioDestino: result.data.cte.destino.xmun,
                    };
                }else {
                    this.cte = {};
                }
                if(result.data.nfse){
                    this.nfse= {
                     ...result.data,
                     numero: result.data.nfse.numero,
                     documentoPrestador: result.data.nfse.documentoPrestador,
                     documentoTomador: result.data.nfse.documentoTomador,
                     nomePrestador: result.data.nfse.nomePrestador,
                     nomeTomador: result.data.nfse.nomeTomador,
                    valor: result.data.nfse.valor,
                    };
                }else{
                    this.result.nfse = {};
                }
                 
            })
        },
        
        enviarCte() {
            this.isLoading = true;
    
            const formData = new FormData();
            formData.append('file', this.cte.cte);
            formData.append('chaveCte', this.cte.chaveCte);
            formData.append('unidadeMedida', this.cte.tipoMedida);
            formData.append('quantidade', this.cte.quantidade);
            formData.append('ufOrigem', this.cte.ufOrigem );
            formData.append('municipioOrigem', this.cte.municipioOrigem );
            formData.append('ufDestino', this.cte.ufDestino );
            formData.append('municipioDestino', this.cte.municipioDestino );
            formData.append('tipoDocumentoFiscal', 'cte' );
            
            AutorizacoesService.informarCte(this.form.autorizacao.id, formData)
            .then(() => {
                this.$buefy.toast.open({
                    message: 'CTe enviado com sucesso!',
                    position: 'is-bottom',
                    type: 'is-success'
                });

                this.$router.push(`/agendamentos?page=1&tipo=${this.$route.query.tipo}&orderBy=id,DESC`);
            }).catch((error) => { 
                if(error.response){
                this.$buefy.toast.open({
                    message: 'Erro ao enviar CTe',
                    position: 'is-bottom',
                    type: 'is-danger'
                });
            }
                
            })
            .finally(() => {
                this.isLoading = false;
            });
        },

        enviarNota() {
            const formData = new FormData ();
            formData.append('file', this.nfse.notafiscal);
            formData.append('numero', this.nfse.numero);
            formData.append('nomePrestador', this.nfse.nomePrestador);
            formData.append('documentoPrestador', this.nfse.documentoPrestador);
            formData.append('nomeTomador', this.nfse.nomeTomador);
            formData.append('documentoTomador', this.nfse.documentoTomador);
            formData.append('tipoPessoaPrestador', '2');
            formData.append('tipoPessoaTomador', '2');
            formData.append('valor', this.nfse.valor);
            formData.append('tipoDocumentoFiscal', 'notaFiscal' );

             
        
            AutorizacoesService.informarNotaFiscal(this.form.autorizacao.id, formData)
            .then(() => {
                this.$buefy.toast.open({
                    message: 'Nota Fiscal enviado com sucesso!',
                    position: 'is-bottom',
                    type: 'is-success'
                });
               // this.$router.push(`/agendamentos?page=1&tipo=${this.$route.query.tipo}&orderBy=id,DESC`);
            })
            .catch((error) => {
                if(error.response){
                this.$buefy.toast.open({
                    message: 'Erro ao enviar Nota Fiscal',
                    position: 'is-bottom',
                    type: 'is-danger'
                });
            }
            })
            .finally(() => {
                this.isLoading = false;
            });
        },
    }

})


</script>