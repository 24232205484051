import Vue from 'vue';
import { format } from 'date-fns';

export default {

  getDocumentos() {
    let requisicao = `documentos`
    return Vue.prototype.$http.get(requisicao);
  },

  getDocumento(id) {
    return Vue.prototype.$http.get(`documentos/${id}`);
  },

  postDocumento(documento) {    
    const form = new FormData();
    form.append('file', documento.file)
    form.append('data', documento.data)
    form.append('tipo', documento.tipo)
    form.append('idPessoa', documento.idPessoa)

    const configHeader = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    return Vue.prototype.$http.post('/documentos', form, configHeader)
  },

  getDocumentosPessoa(idPessoa){
    return Vue.prototype.$http.get(`documentos/pessoa/${idPessoa}`)
  },

  deleteDocumento(id){
    return Vue.prototype.$http.delete(`documentos/${id}`)
  },

  getFoto(nomeFoto){
    return Vue.prototype.$http.get(`documentos/foto/${nomeFoto}`, { responseType: 'blob' })
  },

  baixarDoc(nomeDoc){
    return Vue.prototype.$http.get(`documentos/baixarDoc/${nomeDoc}`, { responseType: 'blob' })
  }

};
